// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
// var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

// Replace link for logo and 'back to main site' button

// $('.mainLogo').attr('href', 'https://www.accesscharityoftheyear.org');
$(".appealActionsWrapper").prependTo(".appealWidgets");

// For each home carousel pages
$("body.homepage .carousel #carouselSlides .carouselSlide").each(
  function () {
    // Remove any reference of linking to said page
    $(this).find("a").removeAttr("href").removeAttr("title");
    $(this).find(".carouselSlideDetail").attr("data-url", "#");
  }
);

/* 2025 */

if ($('body').hasClass('homepage')) {
  $(".postda0c6c51438749439862c670f33bfa7c").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-australia-2025.png' />");
  $(".post2e3cae078fa64ec4aaf118309239252e").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-ireland-2025.png' />");
  $(".postaf8726f2128649fb86d8d32c5387f916").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-malaysia-2025.png' />");
  $(".post08b4ce6d96004057bfc0e05855b0113f").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-new-zealand-2025.png' />");
  $(".post0f8c00b97bbc45a08788a329329a488e").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-romania-2025.jpg' />");
  $(".post04bf649d88664c50a49f0e92939a83dc").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-sri-lanka-2025.png' />");
  $(".post112b6935a5564784a1d2ef95f9288020").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-uk-2025.png' />");
  $(".postcfeff46543de409e9c5d7a79973aaee4").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-usa-2025.png' />");
  $(".posta700e844469346c18405f4fd5c0d388e").append("<img class='charity-logo' src='/fresco/clients/accesscoty2025/assets/logo-vietnam-2025.png' />");
}

if ($('body').hasClass('appeal-beyond-blue')) {
  $("<h2>Australia</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-australia-2025.png' />");
}

if ($('body').hasClass('appeal-alzheimers-society-ireland')) {
  $("<h2>Ireland</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-ireland-2025.png' />");
}

if ($('body').hasClass('appeal-suka-society')) {
  $("<h2>Malaysia & Singapore</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-malaysia-2025.png' />");
}

if ($('body').hasClass('appeal-sir-john-kirwan-foundation')) {
  $("<h2>New Zealand</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-new-zealand-2025.png' />");
}

if ($('body').hasClass('appeal-united-way')) {
  $("<h2>Romania</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-romania-2025.jpg' />");
}

if ($('body').hasClass('appeal-ceylon-school-for-the-deaf-and-blind')) {
  $("<h2>Sri Lanka</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-sri-lanka-2025.png' />");
}

if ($('body').hasClass('appeal-young-lives-vs-cancer')) {
  $("<h2>UK</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-uk-2025.png' />");
}

if ($('body').hasClass('appeal-nord-the-national-organisation-for-rare-disorders')) {
  $("<h2>USA</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-usa-2025.png' />");
}

if ($('body').hasClass('appeal-blue-dragon-childrens-foundation')) {
  $("<h2>Vietnam</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-vietnam-2025.png' />");
}

/* 2024 */

if ($('body').hasClass('appeal-romania-scoala-mamei-junior')) {
  $("<h2>Romania</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-romania.png' />");
}

if ($('body').hasClass('appeal-malaysian-mental-health-association')) {
  $("<h2>Malaysia</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-malaysia.png' />");
}

if ($('body').hasClass('appeal-singapore-childrens-society')) {
  $("<h2>Singapore</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-singapore.jpg' />");
}

if ($('body').hasClass('appeal-child-action-lanka')) {
  $("<h2>Sri Lanka</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-sri-lanka.jpg' />");
}

if ($('body').hasClass('appeal-usa-mental-health-america')) {
  $("<h2>USA</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-usa.png' />");
}

if ($('body').hasClass('appeal-saigon-childrens-charity')) {
  $("<h2>Vietnam</h2>").insertAfter(".title");
  $(".appealWidgets").prepend("<img src='/fresco/clients/accesscoty2025/assets/logo-vietnam.png' />");
}

$(".findAFundraiserWidget .searchFundraisers").replaceWith("<a href='/fundraisers' class='cta-button'>Find a fundraiser</a>");
$(".homeIntro").append('<div class="triangles"><div class="triangle"></div><div class="triangle"></div><div class="triangle"></div></div>');

